import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationService } from '../services/organization.service';
import { OrganizationEntityService } from '../../state/entity-services/organization-entity.service';

@Injectable()
export class LoginPatientGuard implements CanActivate {
  constructor(
    private organizationService: OrganizationService,
    private organizationEntityService: OrganizationEntityService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const organization = this.findOrganization(route);
    if (organization && !['paciente', undefined].includes(organization)) {
      return this.organizationEntityService.getWhitelabel(organization).pipe(
        map(organizationResponse => {
          this.organizationService.setOrganization(organizationResponse);
          this.redirectTo('/paciente/login/boas-vindas', { organization });
          return false;
        })
      );

    }
    return of(true);
  }

  private findOrganization(route: ActivatedRouteSnapshot): string | undefined {
    return route.params['organization'] || route.parent && this.findOrganization(route.parent);
  }

  private redirectTo(path: string, queryParams = {}): void {
    this.router.navigate([path], { queryParams, queryParamsHandling: 'merge', });
  }
}
