import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { Observable } from 'rxjs';
import { Medication, PreviousMedication } from '../models/previous-medication';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PreviousMedicationsEntityService extends BaseEntityService<PreviousMedication> {
  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, {
        singular: 'previousMedication',
        plural: 'previousMedications',
        url: 'previous-medications'
      },
      routerParamsService
    );
    this.nameId = 'previousMedicationId';
  }

  createPreviousMedication(
    medication: Medication,
    personId,
  ): Observable<PreviousMedication> {
    const body: PreviousMedication = {
      active: true,
      person: {
        id: personId,
      },
      medication: {
        id: medication.id,
      },
    };
    return this.add(body);
  }
}
