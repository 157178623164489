import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { Observable } from 'rxjs';
import { PersonExport, PersonExportParams } from '../models/person-import';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PersonExportEntityService extends BaseEntityService<PersonExport> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'personExport', plural: 'personExports', url: 'person-exports' },
      routerParamsService
    );
    this.nameId = 'personExportId';
  }

  personExport(personExportParams: PersonExportParams): Observable<PersonExport> {
    const { organizationId, type, cycleId } = personExportParams;
    const body = {
      organization: { id: organizationId },
      type,
      cycle: {
        id: cycleId,
      }
    };
    return this.add(body);
  }
}

