import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { Observable } from 'rxjs';
import { PersonExport, PersonExportParams, PersonImport } from '../models/person-import';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PersonImportEntityService extends BaseEntityService<PersonImport> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'personImport', plural: 'personImports', url: 'person-imports' },
      routerParamsService
    );
    this.nameId = 'personImportId';
  }

  personExport(personExportParams: PersonExportParams): Observable<PersonExport> {
    const { organizationId, type, cycleId } = personExportParams;
    const body = {
      personExport: {
        organization: { id: organizationId },
        type,
        cycle: {
          id: cycleId,
        }
      },
    };
    return this.httpClient
      .post([environment.apiUrl, 'person-exports'].join('/'), body)
      .pipe(map((response: any) => response?.personExport));
  }
}

