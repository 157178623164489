import { AfterViewInit, Component } from '@angular/core';

import { JwtAuthService } from './shared/services/auth/jwt-auth.service';
import { AmplitudeService } from './shared/services/amplitude.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'patient';

  constructor(
    private amplitudeService: AmplitudeService,
    private jwtAuthService: JwtAuthService) {

  }


  ngAfterViewInit() {
    this.amplitudeService.init(undefined);
    this.jwtAuthService.setUserSentry();
  }
}

