import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'go',
  template: '',
})
export class GoComponent implements AfterViewInit {
  constructor(private router: Router) {
  }

  ngAfterViewInit() {
    this.router.navigateByUrl('/');
  }

}
