import { Injectable } from '@angular/core';
import { FormAnswer } from '../models/form.model';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormAnswersEntityService extends BaseEntityService<FormAnswer> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'formAnswer', plural: 'formAnswers', url: 'form-answers' },
      routerParamsService
    );
    this.nameId = 'formAnswerId';
  }
}
