import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { SharedMaterialModule } from './shared/shared-material.module';
import { SharedModule } from './shared/shared.module';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { PatientGuard } from './shared/guards/patient.guard';
import { LoginPatientGuard } from './shared/guards/login.patient.guard';
import { CommonModule, IMAGE_CONFIG, NgOptimizedImage } from '@angular/common';
import { GoComponent } from './views/go/go.component';
import { StateModule } from './state/state.module';

@NgModule({
  declarations: [
    AppComponent,
    GoComponent,
  ],
  bootstrap: [AppComponent], imports: [
    CommonModule,
    NgOptimizedImage,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    SharedMaterialModule,
    StateModule], providers: [
    LoginPatientGuard,
    PatientGuard,
    {
      provide: IMAGE_CONFIG,
      useValue: {
        placeholderResolution: 40
      }
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
