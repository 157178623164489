import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { PatientGuard } from './shared/guards/patient.guard';
import { LoginPatientGuard } from './shared/guards/login.patient.guard';
import { MenuLayoutComponent } from './shared/components/layouts/menu-layout/menu-layout.component';
import { OrganizationGuard } from './shared/guards/organization.guard';
import { GoComponent } from './views/go/go.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/paciente/login/boas-vindas',
    pathMatch: 'full'
  },
  {
    path: 'paciente/login',
    redirectTo: '/paciente/login/boas-vindas',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () =>
          import('./views/sessions/sessions.module').then(
            m => m.SessionsModule,
          ),
        data: { title: 'Session' },
      },
    ],
  },
  {
    path: '',
    component: MenuLayoutComponent,
    canActivate: [OrganizationGuard],
    children: [
      {
        path: 'paciente',
        loadChildren: () =>
          import('./views/patient/patient.module').then(
            m => m.PatientModule,
          ),
        data: { title: 'Paciente' },
      },
    ],
  },
  {
    path: 'go',
    component: GoComponent,
  },
  {
    path: ':organization/welcome',
    component: AuthLayoutComponent,
    canActivate: [PatientGuard],
  },
  {
    path: ':organization/login',
    component: AuthLayoutComponent,
    canActivate: [LoginPatientGuard],
  },
  {
    path: '**',
    redirectTo: 'sessions/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
