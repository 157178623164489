import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { Observable } from 'rxjs';
import { Disease, PreviousDiseases } from '../models/previous-diseases';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PreviousDiseasesEntityService extends BaseEntityService<PreviousDiseases> {
  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'previousDisease', plural: 'previousDiseases', url: 'previous-diseases' },
      routerParamsService
    );
    this.nameId = 'previousDiseaseId';
  }

}
